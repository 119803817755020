/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is store for user data
 */
import { makeAutoObservable, runInAction } from 'mobx';

import Customer from '../models/Customer';
import { ICustomer } from '../interfaces/IModels';
import Utils from '../lib/Utils';
import API from '../api/API';
import { IGetDealDataResponse } from '../interfaces/IN8N';
import { APIResponse } from '../types/TGeneral';
import type RootStore from './RootStore';
import Logger from '../core/Logger';
import User from '../models/User';

class UserStore {
  private rootStore: RootStore;

  user: User = new User();

  /*
   * this is contact
   */
  customer: ICustomer = new Customer();

  /*
   * query params
   */
  urlParams: Map<any, any> = new Map();

  /**
   * quote number
   */
  public quoteNumber: string = '';

  /*
   * deal id
   */
  crmDealId: string = '';

  /**
   * objectId from parse
   */
  // objectId: string = '';

  /*
   * auth token
   */
  authToken: string = '';

  /*
   * is persist store loaded
   */
  isLoaded: boolean = false;

  /*
   * is contract exist in CRM
   */
  isContactExist: boolean = false;

  /*
   * This is constructor
   */
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;

    this.loadStore().then(() => runInAction(() => this.setIsLoaded(true)));
  }

  /*
   * get boolean value is user authorized and had a authToken and refreshToken
   */
  get isAuthorized(): boolean {
    return !!this.authToken;
  }

  get isMissingDataFormFilled() {
    const { customer } = this;
    let formFilled = true;

    const values = [customer.postcode, customer.email];

    values.map((value) => {
      if (value === '' || value === '0' || !value) {
        formFilled = false;
      }

      return value;
    });

    return formFilled;
  }

  get isCustomerFilled() {
    const isContactFilled =
      !!this.customer.email &&
      !!this.customer.firstName &&
      !!this.customer.lastName &&
      !!this.rootStore.dataStore.internalObjectAddress?.city &&
      !!this.rootStore.dataStore.internalObjectAddress?.street &&
      !!this.rootStore.dataStore.internalObjectAddress?.postcode;

    return isContactFilled;
  }

  /*
   * clear store user store
   */
  clearStore() {
    this.customer = new Customer();

    this.setAuthTokens('');
  }

  /*
   * set isLoaded store or not
   */
  setIsLoaded(value: boolean) {
    this.isLoaded = value;
    Logger.debug(this.isLoaded, 'UserStore - loaded');
  }

  /*
   * set tokens to store
   */
  setAuthTokens(authToken: string) {
    if (authToken || authToken === '') {
      this.authToken = authToken;
    }
  }

  setQuoteNumber(quoteNumber: string) {
    this.quoteNumber = quoteNumber;
  }

  /*
   * update customer to store
   */
  updateCustomer(customer: ICustomer) {
    this.customer = customer;
  }

  updateAddress(customer: ICustomer) {
    this.customer = {
      ...this.customer,
      street: customer.street,
      streetNumber: customer.streetNumber,
      postcode: customer.postcode,
      city: customer.city,
    };
  }

  /*
   * load store from server
   */
  async loadStore() {
    await this.loadDealData();
  }

  /*
   * load deal data
   */
  async loadDealData(): Promise<Nullable<APIResponse<IGetDealDataResponse>>> {
    const dealId = Utils.getQueryParam('dealId') ?? '';
    const token = Utils.getQueryParam('token');

    if (!dealId) {
      return null;
    }

    const res = await API.deal.getData(dealId);

    if (token) {
      this.user = new User(token);
    }

    if (res.isSucceed && !Array.isArray(res.data)) {
      const { quote, deal, contact } = res.data;

      this.crmDealId = deal.id;

      if (contact) {
        this.isContactExist = true;

        this.customer = {
          firstName: contact.firstName ?? '',
          lastName: contact.lastName ?? '',
          email: contact.email ?? '',
          street: contact.address?.street ?? '',
          city: contact.address?.city ?? '',
          postcode: contact.address?.postcode?.toString() ?? '',
          country: contact.address?.country ?? '',
          salutation: contact.salutation ?? '',
          salutationForm: contact.salutationForm ?? 'inPersonal',
          companyName: contact.companyName ?? '',
          type: contact.type,
        };
      }

      this.customer = {
        ...this.customer,
        crmContactId: deal.contactId,
        crmResponsibleUserId: deal.responsibleUserId,
      };

      const isDealAddressFilled = Object.values(deal.address).some((value) => !!value);
      const contactAddress = contact ? contact.address : {};
      const actualAddress = isDealAddressFilled ? deal.address : contactAddress;

      if (actualAddress?.postcode) {
        actualAddress.postcode = actualAddress.postcode.replace(' ', '');
      }

      this.rootStore.dataStore.setInternalObjectAddress(actualAddress);

      this.quoteNumber = quote.number;
      this.authToken = token;

      const parseCustomer = await API.parse.getCustomer(dealId.toString(), quote.number);

      if (parseCustomer?.objectId) {
        this.customer.objectId = parseCustomer.objectId;
      }

      this.rootStore.photovoltaikStore.setImageRoofPlan(deal.imageRoofPlan);
    }

    return res;
  }

  async syncCustomerParseData() {
    if (!this.customer.objectId) {
      const res = await API.parse.createCustomer(this.customer, this.crmDealId, this.quoteNumber);

      if (res) {
        this.customer.objectId = res?.objectId;
      }
    } else {
      await API.parse.updateCustomer(this.customer, this.quoteNumber, this.customer.objectId);
    }
  }

  async syncQuoteNumber(newQuoteNumber: string) {
    if (!this.customer.objectId) {
      return;
    }

    this.quoteNumber = newQuoteNumber;
    await API.parse.updateCustomerProperty('quoteNumber', newQuoteNumber, this.customer.objectId);
  }

  // TODO remove this function if it's not used
  async resetStore() {
    this.customer = new Customer();
    this.urlParams = new Map();
    this.quoteNumber = '';

    const res = (await API.deal.getData(this.crmDealId)) as APIResponse<IGetDealDataResponse>;

    if (res.isSucceed && !Array.isArray(res.data)) {
      const { quote, deal, contact } = res.data;

      this.crmDealId = deal.id;

      this.customer = {
        firstName: contact.firstName ?? '',
        lastName: contact.lastName ?? '',
        email: contact.email ?? '',
        companyName: contact.companyName ?? '',
        crmContactId: deal.contactId,
        crmResponsibleUserId: deal.responsibleUserId,
      };
      this.quoteNumber = quote.number;

      const parseCustomer = await API.parse.getCustomer(this.crmDealId.toString(), quote.number);

      if (parseCustomer?.objectId) {
        this.customer.objectId = parseCustomer.objectId;
      }
    }
  }
}

export default UserStore;
