import { ModuleAreaForm, ProductResultDataType, ProductResultErrorType, ProductResultType } from 'bsh-calculator';

import { WidgetData } from '../components/calculator-widget/utils';
import i18n from '../i18n';
import Utils from '../lib/Utils';
import { FormSwitcher } from '../interfaces/ICommon';
import pages from '../const/pages';

export const createWidgetData = (
  sectionData: ProductResultDataType,
  description: string = '',
  // is feature option checked on financing page
  isFeatureChecked = false,
): WidgetData | null => ({
  title: sectionData.name || '',
  description,
  dropdownData: [
    {
      dropdownTitle: i18n.t('widget.products'),
      dropdownContentStr: (sectionData.data || []).map((product) => `${product.unit || 1} x ${product.name}`),
    },
    {
      dropdownTitle: i18n.t('widget.price'),
      dropdownContentStr: [
        `${i18n.t('widget.priceNet')}: ${Utils.formatPrice(sectionData.summaryPriceNet || 0)} €`,
        `${i18n.t('widget.priceGross')}: ${Utils.formatPrice(sectionData.summaryPriceGross || 0)} €`,
        sectionData.maxLeasingValue ? `${i18n.t('widget.maxLeasingValue')}: ${Utils.formatPrice(sectionData.maxLeasingValue || 0)} €` : '',
        isFeatureChecked && sectionData.leasingRateNet
          ? `${i18n.t('widget.leasingRateNet')}: ${Utils.formatPrice(sectionData.leasingRateNet || 0)} €`
          : '',
        isFeatureChecked && sectionData.leasingRestValue
          ? `${i18n.t('widget.leasingRestValue')}: ${Utils.formatPrice(sectionData.leasingRestValue || 0)} €`
          : '',
        isFeatureChecked && sectionData.leasingCommission
          ? `${i18n.t('widget.leasingCommission')}: ${Utils.formatPrice(sectionData.leasingCommission || 0)} €`
          : '',
        isFeatureChecked && sectionData.rentingRate
          ? `${i18n.t('widget.rentingRate')}: ${Utils.formatPrice(sectionData.rentingRate || 0)} €`
          : '',
        isFeatureChecked && sectionData.rentingCommission
          ? `${i18n.t('widget.rentingCommission')}: ${Utils.formatPrice(sectionData.rentingCommission || 0)} €`
          : '',
        isFeatureChecked && sectionData.monthlyFinancingRate
          ? `${i18n.t('widget.monthlyFinancingRate')}: ${Utils.formatPrice(sectionData.monthlyFinancingRate || 0)} €`
          : '',
        isFeatureChecked && sectionData.monthlyFinancingRateGross
          ? `${i18n.t('widget.monthlyFinancingRateGross')}: ${Utils.formatPrice(sectionData.monthlyFinancingRateGross || 0)} €`
          : '',
      ],
    },
  ],
});

export const createSummaryWidgetData = (
  sectionData: ProductResultDataType,
  description: string = '',
  // is feature option checked on financing page
  isFeatureChecked = false,
): any => ({
  title: '',
  description,
  dropdownData: [{
    dropdownTitle: sectionData.name || '',
    dropdownContentStr: [
      `${i18n.t('widget.priceNet')}: ${Utils.formatPrice(sectionData.summaryPriceNet || 0)} €`,
      `${i18n.t('widget.priceGross')}: ${Utils.formatPrice(sectionData.summaryPriceGross || 0)} €`,
      sectionData.maxLeasingValue ? `${i18n.t('widget.maxLeasingValue')}: ${Utils.formatPrice(sectionData.maxLeasingValue || 0)} €` : '',
      isFeatureChecked && sectionData.leasingRateNet
        ? `${i18n.t('widget.leasingRateNet')}: ${Utils.formatPrice(sectionData.leasingRateNet || 0)} €`
        : '',
      isFeatureChecked && sectionData.leasingRateGross
        ? `${i18n.t('widget.leasingRateGross')}: ${Utils.formatPrice(sectionData.leasingRateGross || 0)} €`
        : '',
      isFeatureChecked && sectionData.leasingRestValue
        ? `${i18n.t('widget.leasingRestValue')}: ${Utils.formatPrice(sectionData.leasingRestValue || 0)} €`
        : '',
      isFeatureChecked && sectionData.leasingCommission
        ? `${i18n.t('widget.leasingCommission')}: ${Utils.formatPrice(sectionData.leasingCommission || 0)} €`
        : '',
      isFeatureChecked && sectionData.rentingRate
        ? `${i18n.t('widget.rentingRate')}: ${Utils.formatPrice(sectionData.rentingRate || 0)} €`
        : '',
      isFeatureChecked && sectionData.rentingRateGross
        ? `${i18n.t('widget.rentingRateGross')}: ${Utils.formatPrice(sectionData.rentingRateGross || 0)} €`
        : '',
      isFeatureChecked && sectionData.rentingCommission
        ? `${i18n.t('widget.rentingCommission')}: ${Utils.formatPrice(sectionData.rentingCommission || 0)} €`
        : '',
      isFeatureChecked && sectionData.monthlyFinancingRate
        ? `${i18n.t('widget.monthlyFinancingRate')}: ${Utils.formatPrice(sectionData.monthlyFinancingRate || 0)} €`
        : '',
      isFeatureChecked && sectionData.monthlyFinancingRateGross
        ? `${i18n.t('widget.monthlyFinancingRateGross')}: ${Utils.formatPrice(sectionData.monthlyFinancingRateGross || 0)} €`
        : '',
    ],
  }],
});

export const getPvWidgetData = (
  calculationResult: ProductResultType | ProductResultErrorType | null,
  quoteNumber: string,
  isFeatureChecked?: boolean,
): { widgetData: Nullable<WidgetData>; errors: string[] } => {
  if (!calculationResult) {
    return { widgetData: null, errors: [] };
  }

  const { pvProducts } = calculationResult as ProductResultType;

  if (!pvProducts) {
    return { widgetData: null, errors: [] };
  }

  if (pvProducts.errors && Array.isArray(pvProducts.errors)) {
    const errorsText = pvProducts.errors?.map(({ error }) => error) ?? [];

    return { widgetData: null, errors: errorsText };
  }
  const productsWithoutPdfOnly = pvProducts.data?.filter((product) => !product.pdfOnly);

  return {
    widgetData: createWidgetData(
      {
        ...pvProducts,
        data: productsWithoutPdfOnly,
      },
      `ANGEBOTSNUMMER \n ${quoteNumber}-PV`,
      isFeatureChecked,
    ),
    errors: [],
  };
};

export const getHotWaterWidgetData = (
  calculationResult: ProductResultType | ProductResultErrorType | null,
  quoteNumber: string,
  isFeatureChecked?: boolean,
): WidgetData | null => {
  if (!calculationResult) {
    return null;
  }
  const { hotWaterProducts } = calculationResult as ProductResultType;

  if (!hotWaterProducts) {
    return null;
  }

  return createWidgetData(hotWaterProducts, `ANGEBOTSNUMMER \n ${quoteNumber}-WP`, isFeatureChecked);
};

export const getBlackoutBoxWidgetData = (
  calculationResult: ProductResultType | ProductResultErrorType | null,
  quoteNumber: string,
  isFeatureChecked?: boolean,
): WidgetData | null => {
  if (!calculationResult) {
    return null;
  }
  const { blackoutBoxProducts } = calculationResult as ProductResultType;

  if (!blackoutBoxProducts) {
    return null;
  }

  return createWidgetData(blackoutBoxProducts, `ANGEBOTSNUMMER \n ${quoteNumber}-BB`, isFeatureChecked);
};

export const getMemoryExtensionWidgetData = (
  calculationResult: ProductResultType | ProductResultErrorType | null,
  quoteNumber: string,
  isFeatureChecked?: boolean,
): { widgetData: Nullable<WidgetData>; errors: string[] } => {
  if (!calculationResult) {
    return { widgetData: null, errors: [] };
  }
  const { memoryExtensionProducts } = calculationResult as ProductResultType;

  if (!memoryExtensionProducts) {
    return { widgetData: null, errors: [] };
  }

  if (Array.isArray(memoryExtensionProducts.errors)) {
    const errorsText = memoryExtensionProducts.errors?.map(({ error }) => error) ?? [];

    return { widgetData: null, errors: errorsText };
  }

  return {
    widgetData: createWidgetData(memoryExtensionProducts, `ANGEBOTSNUMMER \n ${quoteNumber}-SE`, isFeatureChecked),
    errors: [],
  };
};

export const getWallboxWidgetData = (
  calculationResult: ProductResultType | ProductResultErrorType | null,
  quoteNumber: string,
  isFeatureChecked?: boolean,
): { widgetData: Nullable<WidgetData>; errors: string[] } => {
  if (!calculationResult) {
    return { widgetData: null, errors: [] };
  }

  const { wallboxProducts } = calculationResult as ProductResultType;

  if (!wallboxProducts) {
    return { widgetData: null, errors: [] };
  }

  if (Array.isArray(wallboxProducts.errors)) {
    const errorsText = wallboxProducts.errors?.map(({ error }) => error) ?? [];

    return { widgetData: null, errors: errorsText };
  }

  return { widgetData: createWidgetData(wallboxProducts, `ANGEBOTSNUMMER \n ${quoteNumber}-WB`, isFeatureChecked), errors: [] };
};

export const getStorageExtensionData = (
  calculationResult: ProductResultType | ProductResultErrorType | null,
  quoteNumber: string,
  isFeatureChecked?: boolean,
): {
  widgetData: Nullable<WidgetData>;
  errors: string[];
} => {
  if (!calculationResult) {
    return { widgetData: null, errors: [] };
  }

  const { storageExtensionProducts } = calculationResult as ProductResultType;

  if (!storageExtensionProducts) {
    return { widgetData: null, errors: [] };
  }

  if (Array.isArray(storageExtensionProducts.errors)) {
    const errorsText = storageExtensionProducts.errors?.map(({ error }) => error) ?? [];

    return { widgetData: null, errors: errorsText };
  }

  return {
    widgetData: createWidgetData(storageExtensionProducts, `ANGEBOTSNUMMER \n ${quoteNumber}-SN`, isFeatureChecked),
    errors: [],
  };
};

/**
 * Validates the required fields of productProps and returns an error message
 * for the first unfilled required field.
 *
 * @param productProps The product properties to validate.
 * @returns An error message string if a required field is not filled, otherwise an empty string.
 */
export function validateProductProps(productProps: any): string | null {
  const switchers = productProps.possiblePaths as FormSwitcher[];
  const shouldShowPhovoltaik = !!switchers.find((item) => item.url === pages.photovoltaikQuote)?.checked;
  const shouldShowWallbox = !!switchers.find((item) => item.url === pages.wallboxQoute)?.checked;
  const shouldShowHotWater = !!switchers.find((item) => item.url === pages.hotWaterPump)?.checked;
  // const shouldShowStorageExtension = !!switchers.find((item) => item.url === pages.storageExtension)?.checked;
  const shouldShowBlackoutBox = !!switchers.find((item) => item.url === pages.blackoutBox)?.checked;
  const shouldShowMemoryExtension = !!switchers.find((item) => item.url === pages.memoryExtension)?.checked;
  // const shouldShowFinancing = !!switchers.find((item) => item.url === pages.financing)?.checked;

  const requiredFields = [
    { key: 'firstName', label: i18n.t('missingRequiredDataPage.firstName') },
    { key: 'lastName', label: i18n.t('missingRequiredDataPage.lastName') },
    { key: 'email', label: i18n.t('missingRequiredDataPage.email') },
    { key: 'city', label: i18n.t('missingRequiredDataPage.city') },
    { key: 'postcode', label: i18n.t('missingRequiredDataPage.postcode') },
    { key: 'street', label: i18n.t('missingRequiredDataPage.streetName') },
    { key: 'electricityConsumption', label: i18n.t('basicDataPage.electricityConsumption') },
    { key: 'powerConsumption', label: i18n.t('basicDataPage.powerConsumption') },
    { key: 'grossElectricityCosts', label: i18n.t('basicDataPage.grossElectricityCosts') },
    { key: 'moduleAreaTypes', label: i18n.t('photovoltaikPage.moduleAreaType') },
    { key: 'modulType', label: i18n.t('photovoltaikPage.module') },
    { key: 'storages', label: i18n.t('photovoltaikPage.storageSelection') },
    { key: 'moduleAreaForms', label: i18n.t('photovoltaikPage.moduleSurfaces') },
    { key: 'selectionHotWaterHeatPump', label: i18n.t('hotWaterPumpPage.select') },
    { key: 'wallboxSelection', label: i18n.t('wallboxPage.wallboxSelection') },
    { key: 'blackoutBoxSelection', label: `${i18n.t('common.quantity')} Blackout Box` },
    { key: 'memoryExtensionSelection', label: i18n.t('memoryExtensionPage.selectMemoryExtension') },
    // { key: 'financingSelection', label: 'Finanzierung' },
  ];

  const unfilledField = requiredFields.find((field) => {
    if (
      field.key === 'firstName' ||
      field.key === 'lastName' ||
      field.key === 'email' ||
      field.key === 'city' ||
      field.key === 'postcode' ||
      field.key === 'street'
    ) {
      if (!productProps[field.key] || productProps[field.key] === 0 || productProps[field.key] === '') {
        return true;
      }

      return false;
    }

    // Photovoltaik fields
    if (shouldShowPhovoltaik) {
      if (
        field.key === 'electricityConsumption' ||
        field.key === 'powerConsumption' ||
        field.key === 'grossElectricityCosts' ||
        field.key === 'modulType' ||
        field.key === 'storages'
      ) {
        if (!productProps[field.key] || productProps[field.key] === 0 || productProps[field.key] === '') {
          return true;
        }

        return false;
      }

      if (field.key === 'moduleAreaTypes') {
        const isRoofUnselected = (productProps.moduleAreaForms as ModuleAreaForm[]).some((form) => {
          if (form.id !== '' && !form.moduleAreaTypes?.length) {
            return true;
          }

          return false;
        });

        return isRoofUnselected;
      }

      if (field.key === 'moduleAreaForms') {
        const isGeneralUnvalidated = (productProps.moduleAreaForms as ModuleAreaForm[]).some((form) => {
          if (
            form.id !== '' &&
            (!form.moduleAreaLabel ||
              !form.moduleAreaDirectionId ||
              !form.moduleAreaProduction ||
              !form.numbersOfModules ||
              !form.moduleAreaSize)
          ) {
            return true;
          }

          return false;
        });

        return isGeneralUnvalidated;
      }
    }

    if (shouldShowHotWater) {
      if (field.key === 'selectionHotWaterHeatPump') {
        if (!productProps.centralHeatingExist || productProps.selectionHotWaterHeatPump) {
          return false;
        }

        return true;
      }
    }

    if (shouldShowWallbox) {
      if (field.key === 'wallboxSelection') {
        if (!productProps.wallboxSelection) {
          return true;
        }
      }
    }

    if (shouldShowBlackoutBox) {
      if (field.key === 'blackoutBoxSelection') {
        if (!productProps.blackoutBoxSelection) {
          return true;
        }
      }
    }

    if (shouldShowMemoryExtension) {
      if (field.key === 'memoryExtensionSelection') {
        if (!productProps.memoryExtensionSelection) {
          return true;
        }
      }
    }

    return false;
  });

  return unfilledField ? i18n.t('errors.missingField', { label: unfilledField.label }) : '';
}

export const getNetPrices = (calculationResult: ProductResultType | ProductResultErrorType | null): { [key: string]: number } | null => {
  if (!calculationResult) {
    return null;
  }

  const { pvProducts, hotWaterProducts, wallboxProducts, blackoutBoxProducts, memoryExtensionProducts, storageExtensionProducts } =
    calculationResult as ProductResultType;

  const result: { [key: string]: number } = {};

  if (pvProducts && pvProducts.summaryPriceNet) {
    result[pages.photovoltaikQuote] = pvProducts.summaryPriceNet;
  }

  if (hotWaterProducts && hotWaterProducts.summaryPriceNet) {
    result[pages.hotWaterPump] = hotWaterProducts.summaryPriceNet;
  }

  if (wallboxProducts && wallboxProducts.summaryPriceNet) {
    result[pages.wallboxQoute] = wallboxProducts.summaryPriceNet;
  }

  if (blackoutBoxProducts && blackoutBoxProducts.summaryPriceNet) {
    result[pages.blackoutBox] = blackoutBoxProducts.summaryPriceNet;
  }

  if (memoryExtensionProducts && memoryExtensionProducts.summaryPriceNet) {
    result[pages.memoryExtension] = memoryExtensionProducts.summaryPriceNet;
  }

  if (storageExtensionProducts && storageExtensionProducts.summaryPriceNet) {
    result[pages.storageExtension] = storageExtensionProducts.summaryPriceNet;
  }

  return result;
};

export const getSummaryWidgetData = (calculationResult: ProductResultType | ProductResultErrorType | null): WidgetData | null => {
  const leasingRateNet = Object.values(calculationResult as ProductResultType).reduce(
    (acc, section) => acc + (section.leasingRateNet || 0),
    0,
  );

  const leasingRateGross = Object.values(calculationResult as ProductResultType).reduce(
    (acc, section) => acc + (section.leasingRateGross || 0),
    0,
  );

  const rentingRate = Object.values(calculationResult as ProductResultType).reduce((acc, section) => acc + (section.rentingRate || 0), 0);

  const rentingRateGross = Object.values(calculationResult as ProductResultType).reduce(
    (acc, section) => acc + (section.rentingRateGross || 0),
    0,
  );

  const summaryPriceNet = Object.values(calculationResult as ProductResultType).reduce(
    (acc, section) => acc + (section.summaryPriceNet || 0),
    0,
  );

  const summaryPriceGross = Object.values(calculationResult as ProductResultType).reduce(
    (acc, section) => acc + (section.summaryPriceGross || 0),
    0,
  );

  const monthlyFinancingRate = Object.values(calculationResult as ProductResultType).reduce(
    (acc, section) => acc + (section.monthlyFinancingRate || 0),
    0,
  );

  const monthlyFinancingRateGross = Object.values(calculationResult as ProductResultType).reduce(
    (acc, section) => acc + (section.monthlyFinancingRateGross || 0),
    0,
  );

  const totalData = {
    name: 'GESAMT',
    summaryPriceNet,
    summaryPriceGross,
    monthlyFinancingRate,
    monthlyFinancingRateGross,
    leasingRateNet,
    leasingRateGross,
    rentingRate,
    rentingRateGross,
  };

  return createSummaryWidgetData(totalData, '', true);
};
